import Swiper from "swiper";
import {Navigation} from 'swiper/modules';
import 'swiper/css/bundle';
import '@/../css/pages/swiper.css';
import type {SwiperOptions} from "swiper/types/swiper-options";
import {initAuthFn} from "@/pages/auth/helpers";
import {Alpine} from "@/alpineJS";
import propertyCard from "@/modules/propertyCard/property.index";
import favorite from "@/modules/favorite/favorite";

// property card
const listingCardSlideGallery = () => {
    // Store data for auth components
    initAuthFn();
    // store data for favorite
    Alpine.data('favorite', favorite as any)
    // store data for tracking event on click
    Alpine.data('propertyCard', propertyCard as any)
    // init swiper
    if(document.getElementsByClassName('card-swiper-property').length > 0) {
        new Swiper(".card-swiper-property" as any, {
            modules: [Navigation],
            slidesPerView: 1,
            loop: true,
            navigation: {
                nextEl: ".card-swiper-next",
                prevEl: ".card-swiper-prev",
            },
            freeMode: true,
            // lazy: true,
            // updateOnImagesReady: true,
            lazyPreloadPrevNext: 1
        } as SwiperOptions);
    }
}
export default listingCardSlideGallery;